.promotion-table {
    table {
      width: 100%;
      border-collapse: collapse;
  
      thead {
        background-color: #333;
        color: #fff;
  
        th {
          padding: 10px;
          text-align: center;
        }
      }
  
      tbody {
        tr {
          td {
            padding: 0px;
            border: 1px solid #000000;
            text-align: center;
            .upper-part {
                padding-bottom: 10px;
                padding-top: 10px;
                background-color: #dfcb1e;
                border-bottom: 1px solid #000000; 
              }
    
              .lower-part {
                padding-top: 10px;
                padding-bottom: 10px;
                border-top: 1px solid #020202; 
              }
          }
          .custom-cell {
            padding: 10px;
          }
        }
      }
    }
  }
  